import * as React from "react"
import { graphql } from "gatsby"

import PageLayout from "../../templates/page-layout"
import Seo from "../../components/seo"
import ArticleTile from "../../components/article-tile"

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`
	const posts = data.allMarkdownRemark.nodes

	return (
		<PageLayout location={location} title={siteTitle}>
			<Seo title="Blog Overview" description="We blog about knowledge sharing, meeting culture, and much more... Get exclusive business tips on our blog." />
			<div className="relative mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8 xl:pt-8">
				<div className="relative max-w-7xl mx-auto">
					<h1 className="mx-auto text-center text-4xl font-extrabold tracking-tight sm:max-w-lg sm:text-6xl lg:mx-0 lg:text-left">Blog</h1>
					<p className="mt-3 mx-auto text-center max-w-2xl text-xl text-gray-500 sm:mt-4 lg:mx-0 lg:text-left">
						Get exclusive business tips on our blog. We blog about knowledge sharing, meeting culture, and much more...
					</p>
					
					<ol className="mt-12 mb-10 max-w-lg lg:max-w-none mx-auto list-none grid gap-x-8 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
						{
							posts.map(post =>
								<li key={post.fields.slug} className="flex">
									<ArticleTile
										title={post.frontmatter.title}
										description={post.frontmatter.description}
										excerpt={post.excerpt}
										date={post.frontmatter.date}
										slug={post.fields.slug}
										imageUrl={post.frontmatter.imageUrl ? post.frontmatter.imageUrl.childImageSharp.gatsbyImageData.images.fallback.src: null}
										authorName={post.frontmatter.authorName}
										authorUrl={post.frontmatter.authorUrl}
										authorImageUrl={post.frontmatter.authorImageUrl}
										readingTime={post.frontmatter.readingTime}
									/>
								</li>
							)
						}
					</ol>
				</div>
			</div>
		</PageLayout>
	)
}

export default BlogIndex

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					description
					imageUrl {
						childImageSharp {
							gatsbyImageData(webpOptions: {quality: 100}, formats: WEBP)
						}
					}
					authorName
					authorImageUrl
					readingTime
				}
			}
		}
	}
`